import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { usePlatformTheme } from '@/components/common/ThemeContext';

type Props = ChubbsProps & {
  LightChubbs: FC<ChubbsProps>;
  DarkChubbs: FC<ChubbsProps>;
  dataQa?: string;
};

export const Chubbs: FC<Props> = React.memo((props) => {
  const {
    LightChubbs,
    DarkChubbs,
    ...restProps
  } = props;

  const { isDarkMode } = usePlatformTheme();

  const ChubbsComponent = isDarkMode
    ? DarkChubbs
    : LightChubbs;

  return (
    <ChubbsComponent {...restProps} data-qa={restProps.dataQa} />
  );
});
